<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getVezaneLinije)"/>
			</el-col>
			<el-col :span="12" align="right">
				<el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/vezane-linije/create')" v-if="$utils('roleUser', 'Vezane_linije', 'create')">{{ $t('global.novo') }}</el-button>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table v-if="vezaneLinije.length"
						:data="vezaneLinije"
						ref="table"
						:default-sort="sortBy"
						@sort-change="sort => $utils('changeSort', sort, getVezaneLinije)"
						v-loading="gridLoad"
						border
						stripe>
				<el-table-column
						align="left"
						prop="naziv"
						:label="$t('vezaneLinije.naziv')"
						sortable="custom">
				</el-table-column>
				<el-table-column
						align="center"
						prop="aktivno"
						:label="$t('vezaneLinije.aktivno')"
						sortable="custom"
						width="120">
					<template slot-scope="scope">
						<el-button size="mini" @click="statusUpdate(scope.row.id)">
								<i v-if="scope.row.aktivno === 'NE'" class="fas fa-times-circle color-text--red"></i>
								<i v-else-if="scope.row.aktivno === 'DA'" class="fas fa-check-circle color-text--green"></i>
						</el-button>
					</template>
				</el-table-column>
				<el-table-column

						width="120">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Vezane_linije', 'edit')">
							<el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/vezane-linije/' + scope.row.id + '')" size="small"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Vezane_linije', 'delete')">
							<el-button type="danger" icon="fas fa-trash" @click="$delete('vezaneLinije', scope.row.id)" size="small"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getVezaneLinije" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'vezane-linije-list',
	data() {
		return {
			vezaneLinije: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('vezaneLinije.vezaneLinije')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getVezaneLinije()]);
	},
	methods: {
		getVezaneLinije() {
			this.gridLoad = true;
			return this.$get('vezaneLinije', this.$utils('getParams')).then(data => {
				this.vezaneLinije = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		},
		statusUpdate(id) {
			this.$confirm(this.$t('confirm.opisPromeniStatus'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.gridLoad = true;
				this.$put('vezaneLinijeStatus', { id: id }, {}).then(() => {
					this.getVezaneLinije();
				}).catch(() => {
					this.gridLoad = false;
				});
			});
		}
	}
};
</script>
